import React from "react";
import { Link } from "gatsby";

const Service = ({ title, url, children }) => {
    return (
      <div className="w-full p-3">
        <Link
          to={url}
          className="text-2xl text-purple-900 hover:text-purple-400 hover:underline"
        >
          {title}
        </Link>
        <p>{children}</p>
      </div>
    );
};

const ServicesList = ({className, children, ...props})  => {
    return (
        <div className="flex flex-wrap -mx-3 overflow-hidden sm:-mx-6 md:-mx-2 lg:-mx-3 xl:-mx-3 mt-5">
        <div className="my-3 px-3 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-3 lg:px-3 xl:my-3 xl:px-3">
          <Service
            title="One to One Coaching"
            url="/one-to-one-executive-coaching"
          >
    One to One coaching helps you create or achieve what your version of success is, which is unique to you alone
          </Service>
        </div>
        <div className="my-3 px-3 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-3 lg:px-3 xl:my-3 xl:px-3">
          <Service
            title="Teams and Groups"
            url="/executive-team-coaching"
          >
Coaching enables lasting change by providing an environment where individuals can explore how they interact, and their impact, within a team  
          </Service>
        </div>
      </div>
    
    );

};

export default ServicesList;