import React, { Component } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import SEO from "../components/seo";
import ServicesList from "../components/servicesList";

const BackgroundSection = ({ data , className, children }) => {


  // Set ImageData.
  const imageData = data.file.childImageSharp.fluid
  
  return (
      <BackgroundImage
        Tag="div"
        className={className}
        fluid={imageData}
      >
        {children}
      </BackgroundImage>
  )
}


const Service = ({ title, url, children }) => {
  return (
    <div className="w-full p-2">
      <Link
        to={url}
        className="text-2xl text-teal-700 hover:text-indogo-600 hover:underline"
      >
        {title}
      </Link>
      <p>{children}</p>
    </div>
  );
};

const Services = ({ data }) => {
  const services = data.allMarkdownRemark.edges;
  const imageData = data.banner.childImageSharp.fluid;
  const infoGraphicFluid = data.infoGraphic.childImageSharp.fluid;


  return (
    <Layout headerClass="relative bg-white" bodyClass="px-0 md:px-0 lg:px-0 min-h-screen">
      <SEO title="Services" />

      <div
        className="min-h-screen h-full flex flex-col"
        
      >
              <BackgroundImage
        Tag="div" className="w-full h-40 sm:h-32 lg:h-56"  fluid={imageData}>
        <div className="container mx-auto px-4 sm:px-2 md:px-20 lg:px-24 mb-24">
          <div className="w-7/12 mt-10 sm:mt-12 lg:mt-20 font-heading self-start">
            <h1 className="text-4xl md:text-3xl lg:text-6xl text-pink-600 leading-tight text-shadow-xl">Services</h1>
          </div>
          </div>
          </BackgroundImage >
          <div className="container mx-auto pb-10  md:px-20 lg:px-56 px-6">
            <div className="mt-6 p-1 bg-teal-900 heropattern-texture-teal-600"> 
              <div className="text-base  self-center m-4" >
                <p className="text-white text-shadow-sm">I work with individuals and groups to maximise their performance, success and wellbeing.  My approach is practical, results driven and tailored to each individual to adapt and thrive from challenges or disruption that they are experiencing.</p>
              </div>
            </div>
          <div className="mx-auto px-2 mt-10 mb-5">
            <ServicesList />
          </div>

        </div>
        <div className="container mx-auto px-6 md:px-10 lg:px-16 mb-5">
            <div className="flex flex-col mt-5 mb-5 items-center"> 
                <div className="text-4xl self-center">
                  <h2 className="font-heading text-teal-900">My Coaching Process</h2>
                </div>
                <div className="w-full sm:w-3/4 md:w-3/4 lg:w-1/2 xl:w-1/2">
                  <Img 
                    fluid={infoGraphicFluid} 
                    alt="Angela Cooney - Executive Coach Process - Dublin - Ireland" 
                  />
                </div>
            </div>
        </div>
         
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    banner: file(relativePath: { eq: "services-page-compass-banner.jpg"}) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      infoGraphic: file(relativePath: { eq: "coach-process-info-graphic-min.png"}) {
        childImageSharp {
          fluid( maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
          }
          excerpt
        }
      }
    }
  }
`;
export default Services;
